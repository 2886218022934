import styled from "styled-components";
import Fade from 'react-reveal'
import Slide from 'react-reveal'
import Koala from '../images/GIFKoala_white.gif'
import Pizza from '../images/dead-pizza.png'

const Illustrations = () => {
    return (
        <Container id = "illustrations">
            <Wrapper>
                <FirsRow>
                    <img
                        src={Koala}
                        alt="Picture of a moving koala"
                        width={400}
                        height={400}/>
                    <p>Look at this little guy! 
                    <br></br> Born and raised by Adobe CC.
                    </p>
                </FirsRow> 
            </Wrapper>
        </Container>

    )
}

export default Illustrations

const Container = styled.div `
    width: 100%;
    height: auto;
    display: flex; 
    justify-content: center; 
    
`
const Wrapper = styled.div`
    width: 100%; 
    //padding: 0 7rem 0 7rem; 
    @media (max-width:500px) {
        width: 50%; 
    }
`
const FirsRow = styled.div `
    display: flex;
    align-items: center; 
    justify-content: space-between;
    @media (max-width:500px) {
        flex-direction: column-reverse; 
    }
    
`