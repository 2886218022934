import React from "react";
import Fade from "react-reveal/Fade";
import Me from '../images/josefine_pic_thin_2.jpg'

const About = () => {
    return (
      <div className="section" id="about">
          <Fade bottom cascade>
        <div className="about-container">            
            <div className="about-section">
                <div className="content">
                    <h1>About</h1>              
                    <p>With a master degree in interactive media technology, specified towards sound and music computing, my previous projects have been in the intersection of art, technology and HCI research. Experience lies within physical interaction combined with digital computing, which includes prototyping with everything from microcontrollers, sensors and 3D-printing to various design methods. Previous projects have mainly been around audio-visual interaction, which have included profound user research and testing. </p>
                    <p>I spent the first half of 2021 as an intern at Manyone, which gave me great insight in cross-disciplinary teams. Parallel to my intern position, I worked at KTH as a studio manager of the creative studio <a href="https://www.kth.se/hct/mid/research/research-environment/middla-1.854121">MIDDLA</a>, which included maintenance of 3D-printers, laser cutters, managing prototyping material as well as teaching other students how to use the space. Currently, I am improving my frontend and graphic design skills.</p>
                    <p>I like to learn new things, all from programming languages to tools to methods, to be able to create greater projects. If you have an oppertunity where I can keep developing and learning within interaction design, UX or anything similar, feel free to <a href="#contact">contact me.</a> </p>
                                      
                </div>
                <div className="image-wrapper">
                <img src={Me} alt="about" ></img>
                </div>
            </div>
            
        </div>
        </Fade>
      </div>
    )
  }
  
  export default About