import React from "react"
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';
import Button from 'react-bootstrap/Button';





const Card = ({ heading, paragraph, imgUrl, projectLink, hide }) => {

  return (
    <div
      className="card"
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.2)),url(" +
          imgUrl + ")",
      }}
    >
      <div className="content">
        <h1 className="header">{heading}</h1>
        <p className="text">{paragraph}</p>
      </div>

    </div>
  )
}

export default Card
