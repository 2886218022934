import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Josefine from '../images/josefine_live_500px.jpg';
import Rapcamp from '../images/rapcamp_2.jpg';
import Spugatti from '../images/spugatti_500px.jpg';
import Bug from '../images/bug-orchestra-1.jpg';
import Illustrations from './Illustrations';

const Slideshow = () => {
  return (
    <>
    
    <div className="slide-section" id="slide">
        <div className="slide-container">
          <div className="slide-wrapper">
          <h1>Other stuff </h1>
          <Illustrations /> 
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Josefine}
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Music</h3>
                <p>Producing, live sets and DJ. <a href="https://open.spotify.com/artist/0PV8a0xZbBYtdGB3dLyWU5?si=OC2cHqFkSMOt6kUKfhg_Gg&dl_branch=1"> Check me out on Spotify! </a></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Rapcamp}
                alt="Second slide"
              />
              <Carousel.Caption>
                <h3>Pizzaslize Rapcamp</h3>
                <p>Organizer of Rapcamp - a music and art camp for young adults. <a href="https://open.spotify.com/album/5mZfgm2TvZUEqyoDorqGce?si=BiQLZnyATxGlsjxh0VAfIQ&dl_branch=1"> Check out Rapcamp Vol.1! </a></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                width="100%"
                className="d-block w-100"
                src={Spugatti}
                alt="Third slide"
              />
              <Carousel.Caption>                
                <h3>Spugatti</h3>
                <p>A fully functional Lamborghini Avendor in cardboard. Built for Leavin' La Vida Larsson album promotion. <a href="https://youtu.be/WmwgdFoJ4L4">Check it out!</a></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                width="100%"
                className="d-block w-100"
                src={Bug}
                alt="Third slide"
              />
              <Carousel.Caption>                
                <h3 style = {{color: 'white'}}>Bug Orchestra</h3>
                <p style = {{color: 'white'}}>An art residence for bugs, a facility designed to adequately stimulate bugs’ creativity, give them compositional freedom and music performance training. An idea so stupid it got accepted to Absurd Stupid Hackaton at Queen Mary's University in London.  <a href="https://youtu.be/WmwgdFoJ4L4">Read about it here</a></p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        </div>
    </div>
    </>
  )
}

export default Slideshow
