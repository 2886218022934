import Card from "./Card"
import Fade from "react-reveal/Fade"
import Soypiano from '../images/Sushitable.JPG'
import Rullen from '../images/Rullen-fancy.jpg'
import Zebra from '../images/ZEBRA-jpg.jpg'
import Wearable from '../images/wearable-room-2.jpg'
import Thesis from '../images/master-thesis-2.png'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from "react";
import Wearable_2 from '../images/wearable-room-3.jpg';
import Quickie from '../images/quicke-grace-3.jpg';

import ReactPlayer from "react-player";


{/*function Work () {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  return (
    <div className="work-section" id="work">
      <div className="work-container">
        <div className="work-wrapper">
          <Fade bottom cascade>
            <h1>Projects</h1>
          </Fade>
          <Fade >
          <div className="grid" >           
                <Card 
                  key="1"
                  heading="Zebra"
                  paragraph="ZEBRA is an audio-visual eyetracking system that let artists turn their analogue art piece into an interactive experience. It's providing unique possibilities for artists to create, and viewers to experience, interactive audio-visual art.
                  ZEBRA was used in the art piece Rymdbruk created by the visual artist Kim Demåne (Delicious Brains) and the musician Frej Larsson, diplayed at the art exhibit in Ronneby Konsthall 2021.
                  The system and research around it regarding visual attention and cross-modalities influence on short-term memory was presented and published at AudioMostly 2021."
                  imgUrl={Zebra}
                  projectLink={"https://vimeo.com/574431240"}
                  hide = "true"
                ></Card>
                <Card
                  key="2"
                  heading="The Rullen Band"
                  paragraph="The Rullen band is a set of four instruments designed to be played by people with severe to moderate cognitive and physical disabilities. The aim is that with inclusive design enable anyone to be a part of a band, regardless of their abilities.
                  The Rullen Band makes music inclusive and accessible, and states that everyone should have the opportunity of enjoying music together with others.
                  The Rullen Band has been used at Dibber Rullen School, Stockholm, in their musical education spring term of 2021 with great success. The work has also been presented and published at NIME 2021."
                  imgUrl={Rullen}
                  projectLink={"https://vimeo.com/607694507"}
                  hide = "true"
                ></Card>
                <Card
                  key="3"
                  heading="Soypiano"
                  paragraph="An interactive sushi table that lets the user explore the sonification of having a dinner. 
                  The work is a result of a voice and gesture sketching session with a four-year-old, that got to describe his dream imaginary instrument.
                  The work was presented at the Swedish Museum of Performing Arts."
                  imgUrl={Soypiano}
                  projectLink={"https://vimeo.com/607696089"}
                  hide = "true"
                ></Card>            
                </div>
                <div className="grid-2" >
                  <div onClick={handleShow}>      
                    <>
                    <Modal
                      show={show} 
                      onHide={handleClose}
                      size="lg"
                      aria-labelledby="example-custom-modal-styling-title"
                      centered
                      //backdrop="static"
                      >
                      <Modal.Body
                      style = {{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gridGap: "30px",
                        fontSize: "12px",
                      }}>
                        <img src={Wearable_2} alt="wearable_large" />
                        <div className="modal-text">  
                          <p>
                            The Wearable Room is a prototype designed and builts for an explorative study about e-textiles. 
                            It is a jacket hooked up with a Bela controller that, through your headphones, 
                            filters the outside soundscape to create a more pleasant audio feedback - without having you completely 
                            shut off from your surrounding. 
                            <br />
                            <br />
                            The user can control and manipulate the characteristics of the audio output through the 
                            numerous sensors integraded in the jacket.
                            
                          </p>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    </>
                    <Card
                    key="4"
                    heading="Wearable Room"
                    paragraph="The wearable room is a jacket that explores the soundscape of our future. It works as a shield between you and the outside world if you want to create a feeling of being more undisturbed, private and calm."
                    imgUrl={Wearable}
                    ></Card>
                  </div>
                  <div onClick={handleShow2}>      
                    <>
                    <Modal
                      show={show2} 
                      onHide={handleClose2}
                      size="lg"
                      aria-labelledby="example-custom-modal-styling-title"
                      centered     
                      >

                      <Modal.Body
                      style = {{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gridGap: "30px",
                        fontSize:"12px",
                      }}>
                        <img src={Thesis} alt="thesis-modal" width="388px" />
                        <div className="modal-text">  
                          <p>
                            This study investigated how visual feedback affects soundscape perception. 
                            <br />
                            <br />
                            It serves as an introduction of how digital audio-visual computing and cross-disciplinary methods can be applied for soundscape improvement. 
                            A particle system was developed in Max/MSP to be displayed in a static, moving or audio-reactive manner in relation to a given soundscape. 
                            <br />
                            <br />
                            The results indicate that visual feedback reactive to the positive sounds in a soundscape, decreases the perception of 
                            negative sounds in the same soundscape compared to static visual feedback. It is shown that visual feedback can draw attention to specific 
                            sound sources in a soundscape and it is the velocity, shape and movement of the visuals that primarily affects the attentional shift. 
                            <br />
                            <br />
                            This study is an appropriate basis for further investigation on digital visual feedback’s effect on soundscape perception, 
                            and how the findings can be applied in a real-world setting.                            
                          </p>
                        </div>
                      </Modal.Body>
                    </Modal>
                    </>
                    <Card
                    key="5"
                    heading="Master thesis project"
                    paragraph="The wearable room is a jacket that explores the soundscape of our future. It works as a shield between you and the outside world if you want to create a feeling of being more undisturbed, private and calm."
                    imgUrl={Thesis}
                    ></Card>
                  </div>
                  <Fade>
                    <Card
                    key="6"
                    heading="Quickie Grace"
                    paragraph="Quickie Grace - the interactive 'get ready for school' application, motivating children to do their morning
                    tasks by following Quicky Grace through hers. It uses NFC-tag technology as interaction between the game and the user, and is programmed in Unity. The child blips
                    a 3D-printed key containing a NFC-tag corresponding to a completed morning task. The child can then continue to the next,
                    trying to beat Molly the Mole and earning points along the way! The tasks and time can be set up by the parent."
                    imgUrl={Quickie}
                    projectLink={"https://vimeo.com/613720001"}
                    hide = "true"
                  ></Card>  
                  </Fade>
                  </div>   
                </Fade>   

        </div>
      </div>
      
    </div>
  )
}

export default Work */}

function Work() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);


  return (
    <div className="work-section" id="work">
      <div className="work-container">
        <div className="work-wrapper">
          <Fade bottom cascade>
            <h1>Projects</h1>
          </Fade>

          <div className="grid" >     

            <div onClick={handleShow4}>     
              <Card 
                key="1"
                heading="Zebra"
                paragraph="ZEBRA is an audio-visual eyetracking system that let artists turn their analogue art piece into an interactive experience. It's providing unique possibilities for artists to create, and viewers to experience, interactive audio-visual art.
                ZEBRA was used in the art piece Rymdbruk created by the visual artist Kim Demåne (Delicious Brains) and the musician Frej Larsson, diplayed at the art exhibit in Ronneby Konsthall 2021.
                The system and research around it regarding visual attention and cross-modalities influence on short-term memory was presented and published at AudioMostly 2021."
                imgUrl={Zebra}
              ></Card>
            </div>
            <Modal          
              show={show4} 
              onHide={handleClose4}
              size="lg"
              aria-labelledby="example-custom-modal-styling-title"
              centered>
                <Modal.Header closeButton id="close-button"/>
              <Modal.Body 
              style = {{
                //display:"flex",
                justifyContent:"center",
                alignItems:"center",
                fontSize:"14px",
              }}>          
                <ReactPlayer className='react-player' url="https://vimeo.com/574431240" width="100%"/>
                <p id="modal-text">
                ZEBRA is an audio-visual eyetracking system that let artists turn their analogue art piece into an interactive experience. It's providing unique possibilities for artists to create, and viewers to experience, interactive audio-visual art.
                ZEBRA was used in the art piece Rymdbruk created by the visual artist Kim Demåne (Delicious Brains) and the musician Frej Larsson, diplayed at the art exhibit in Ronneby Konsthall 2021.
                The system and research around it regarding visual attention and cross-modalities influence on short-term memory was presented and published at AudioMostly 2021.
                </p>
              </Modal.Body>
            </Modal>

            <div onClick={handleShow5}>
                <Card
                  key="2"
                  heading="The Rullen Band"
                  paragraph="The Rullen band is a set of four instruments designed to be played by people with severe to moderate cognitive and physical disabilities. The aim is that with inclusive design enable anyone to be a part of a band, regardless of their abilities.
                  The Rullen Band makes music inclusive and accessible, and states that everyone should have the opportunity of enjoying music together with others.
                  The Rullen Band has been used at Dibber Rullen School, Stockholm, in their musical education spring term of 2021 with great success. The work has also been presented and published at NIME 2021."
                  imgUrl={Rullen}
                ></Card>
              </div>
            <Modal          
                  show={show5} 
                  onHide={handleClose5}
                  size="lg"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered>
                    <Modal.Header closeButton id="close-button"/>
                  <Modal.Body 
                  style = {{
                    //display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"14px",
                  }}>          
                    <ReactPlayer className='react-player' url="https://vimeo.com/607694507" width="100%"/>
                    <p id="modal-text">
                    The Rullen Band is a set of four instruments designed to be played by people with severe to moderate cognitive and physical disabilities. The aim is that with inclusive design enable anyone to be a part of a band, regardless of their abilities.
                    The Rullen Band makes music inclusive and accessible, and states that everyone should have the opportunity of enjoying music together with others.
                    The Rullen Band has been used at Dibber Rullen School, Stockholm, in their musical education spring term of 2021 with great success. The work has also been presented and published at NIME 2021.</p>
                  </Modal.Body>
                </Modal>

            <div onClick={handleShow6}>
                <Card
                  key="3"
                  heading="Soypiano"
                  paragraph="An interactive sushi table that lets the user explore the sonification of having a dinner. 
                  The work is a result of a voice and gesture sketching session with a four-year-old, that got to describe his dream imaginary instrument.
                  The work was presented at the Swedish Museum of Performing Arts."
                  imgUrl={Soypiano}
                ></Card>   
                </div> 
              <Modal          
                show={show6} 
                onHide={handleClose6}
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
                centered>
                  <Modal.Header closeButton id="close-button"/>
                <Modal.Body 
                style = {{
                  //display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  fontSize:"14px",
                }}>          
                  <ReactPlayer className='react-player' url="https://vimeo.com/607696089" width="100%"/>
                  <p id="modal-text">
                  An interactive sushi table that lets the user explore the sonification of having a dinner. 
                  The work is a result of a voice and gesture sketching session with a four-year-old, that got to describe his dream imaginary instrument.
                  The work was presented at the Swedish Museum of Performing Arts.</p>
                </Modal.Body>
              </Modal>


            <div onClick={handleShow}>
              <Card
              key="4"
              heading="Wearable Room"
              paragraph="The wearable room is a jacket that explores the soundscape of our future. It works as a shield between you and the outside world if you want to create a feeling of being more undisturbed, private and calm."
              imgUrl={Wearable}
              ></Card>
            </div>
            <Modal          
            show={show} 
            onHide={handleClose}
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
            centered>
              <Modal.Header closeButton id="close-button"/>
            <Modal.Body 
            style = {{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gridGap: "30px",
              fontSize:"14px",
            }}>
               <img src={Wearable_2} alt="wearable" width = "30%"/>   
              <p>
                The Wearable Room is a prototype designed and built for an explorative study about e-textiles. 
                It is a jacket hooked up with a Bela controller that, through your headphones, 
                filters the outside soundscape to create a more pleasant audio feedback - without having you completely 
                shut off from your surrounding. 
                <br />
                <br />
                The user can control and manipulate the characteristics of the audio output through the 
                numerous sensors integraded in the jacket.
              </p>
  
            </Modal.Body>
          </Modal>

            <div onClick={handleShow2}>
              <Card
              key="5"
              heading="Master Thesis Project"
              paragraph="A study investigated how digital visual feedback affects soundscape perception. "
              imgUrl={Thesis}
              ></Card>
            </div>
            <Modal          
            show={show2} 
            onHide={handleClose2}
            size="lg"
            dialogClassName="model_l"
            aria-labelledby="example-custom-modal-styling-title"
            centered>
              <Modal.Header closeButton id="close-button"/>
            <Modal.Body 
            style = {{
              display:"flex",
              justifyContent: "center",
              alignItems: "center",
              gridGap: "30px",
              fontSize:"14px",
            }}>
               <img src={Thesis} alt="theses" width="50%" id="image" />   
               <p>
                  This study serves as an introduction of how digital audio-visual computing and cross-disciplinary methods can be applied for soundscape improvement. 
                  A particle system was developed in Max/MSP to be displayed in a static, moving or audio-reactive manner in relation to a given soundscape. 
                  <br />
                  <br />
                  The results indicate that visual feedback reactive to the positive sounds in a soundscape, decreases the perception of 
                  negative sounds in the same soundscape compared to static visual feedback. It is shown that visual feedback can draw attention to specific 
                  sound sources in a soundscape and it is the velocity, shape and movement of the visuals that primarily affects the attentional shift. 
                  <br />
                  <br />
                  This study is an appropriate basis for further investigation on digital visual feedback’s effect on soundscape perception, 
                  and how the findings can be applied in a real-world setting.                            
                </p>
              {/*<Button id="close-button" className="primary-btn" onClick={handleClose2}>Close</Button> */}
            </Modal.Body>
          </Modal>
          
            <div onClick={handleShow3}>
              <Card
                key="6"
                heading="Quickie Grace"
                paragraph="Quickie Grace - the interactive 'get ready for school' application, motivating children to do their morning
                tasks by following Quicky Grace through hers. It uses NFC-tag technology as interaction between the game and the user, and is programmed in Unity. The child blips
                a 3D-printed key containing a NFC-tag corresponding to a completed morning task. The child can then continue to the next,
                trying to beat Molly the Mole and earning points along the way! The tasks and time can be set up by the parent."
                imgUrl={Quickie}
              ></Card>  
            </div>
            <Modal          
              show={show3} 
              onHide={handleClose3}
              size="lg"
              aria-labelledby="example-custom-modal-styling-title"
              centered
              scrollable>
            <Modal.Header closeButton id="close-button"/>
            <Modal.Body 
            style = {{
              //display:"flex",
              justifyContent:"center",
              alignItems:"center",
              fontSize:"14px",
            }}>          
              <ReactPlayer className='react-player' url="https://vimeo.com/613720001" width="100%"/>
              <p id="modal-text">
                Quickie Grace - the interactive 'get ready for school' application, motivating children to do their morning
                tasks by following Quicky Grace through hers. It uses NFC-tag technology as interaction between the game and the user, and is programmed in Unity. The child blips
                a 3D-printed key containing a NFC-tag corresponding to a completed morning task. The child can then continue to the next,
                trying to beat Molly the Mole and earning points along the way! The tasks and time can be set up by the parent.</p>
            </Modal.Body>
          </Modal>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work

